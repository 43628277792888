.btn-primary {
    background-image: linear-gradient(90deg, rgb(254, 161, 3) 0%, rgb(251, 10, 10) 100%);
    border: none;
    box-shadow: 0 0 20px rgba(#000, .8);
    padding: 14px 100px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    transition: background-image .2s ease;
    border-radius: 100px;
    outline: none !important;
    text-decoration: none !important;
    &:hover {
        background-image: linear-gradient(180deg, rgb(251, 10, 10) 0%, rgb(251, 10, 10) 100%);
    }

    @include media-breakpoint-down(md) {
        padding: 14px 20px;
        max-width: 100%;
        font-size: 16px;
        white-space: normal;
    }
}

.styled-title {

    margin-bottom: 35px;
    padding-bottom: 20px;
    position: relative;
    font-size: 36px;
    font-weight: 900;
    text-align: center;

    @include media-breakpoint-down(md) {
        font-size: 22px;
    }

    &:before {
        position: absolute;
        left: 50%;
        bottom: 0;
        content: '';
        background-image: linear-gradient(90deg, rgb(254, 161, 3) 0%, rgb(251, 10, 10) 100%);
        width: 100px;
        margin-left: -50px;
        height: 6px;
    }

    .small {
        font-size: 18px;
        font-weight: normal;
        display: block;
        padding-top: 6px;
        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
    }

}

.form-control {
    box-shadow: 0 0 16px rgba(#000, .2);
    border-radius: 100px;
    outline: none !important;
    &:focus {
        box-shadow: 0 0 16px rgba(#000, .4);
        border-color: #ffffff;
    }
}

textarea.form-control {
    border-radius: 20px;
    min-height: 120px;
}

body {
    font-size: 16px;
    @include media-breakpoint-down(md) {
        font-size: 14px;
    }
}