.b-park {

    position: relative;
    z-index: 2;
    background-color: #ffffff;
    padding-top: 100px;
    padding-bottom: 20px;
    box-shadow: 0 4px 16px rgba(#000, .6);

    @include media-breakpoint-down(lg) {
        padding-top: 20px;
    }

    &:before {
        position: absolute;
        width: 100%;
        content: '';
        height: 142px;
        left: 0;
        top: -142px;
        background-image: url(../img/first-bg.png);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        display: none;
    }

    &__after {
        position: absolute;
        bottom: calc(100% - 80px);
        left: 0;
        width: 40%;
        z-index: 2;
        content: '';
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .item {
        width: 100%;
        border-radius: 5px;
        background-color: #ffffff;
        padding: 20px 10px 10px;
        box-shadow: 0 0 16px rgba(#000, .2);
        transition: all .2s ease;
        text-align: center;
        margin-bottom: 30px;
        @include media-breakpoint-down(md) {
            margin-bottom: 25px;
        }
        &:hover {
            box-shadow: 0 0 16px rgba(#000, .3);
        }

        .img {
            display: block;
            margin-bottom: 10px;
            img {
                max-width: 100%;
            }
        }

        .name {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
            display: block;
        }

        .value {
            display: inline-flex;
            width: 50%;
            padding: 0 22px;
            font-size: 14px;
            margin-bottom: 5px;
            justify-content: flex-start;
            align-items: center;
            img {
                margin-right: 5px;
            }
        }

        .order {
            margin-top: 5px;
            padding-top: 10px;
            border-top: 1px solid #ccc;
        }

        .price {
            text-transform: uppercase;
            font-size: 14px;
            line-height: 1.3;
            max-width: 65px;
            text-align: left;
            .num {
                font-size: 18px;
                font-weight: 900;
            }
        }

        .btn-primary {
            font-size: 14px;
            padding: 9px 40px;
            box-shadow: none;
            border-radius: 8px;
        }

    }

}
