.b-form {
    background-color: #f3f4f4;
    padding: 40px 0;
    box-shadow: 0 -4px 16px rgba(#000, .6);
    background-image: url(../img/texture.png);
    background-repeat: repeat;

    .link {
        display: flex;
        width: 100%;
        margin-bottom: 25px;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 16px;
        color: #949494;

        @include media-breakpoint-down(md) {
            display: block;
            text-align: center;
        }

        .val {
            display: block;
            font-size: 24px;
            font-weight: 900;
            a {
                color: #000000;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .ico {
            width: 75px;
            margin-right: 5px;
            @include media-breakpoint-down(md) {
                margin: 0 auto 10px;
            }
            img {
                max-width: 100%;
            }
        }
    }

    .btn-primary {
        box-shadow: none;
        padding: 7px 100px;
        font-size: 16px;
        cursor: pointer;
    }

}
