.b-rent {
    padding: 30px 0 60px;

    .title {
        text-align: center;
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 25px;
        text-transform: uppercase;
        @include media-breakpoint-down(md) {
            font-size: 20px;
        }
        .small {
            font-weight: bold;
            font-size: 18px;
            display: block;
            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
        }
    }
}
