.b-footer {
    padding: 20px 0;
    background-color: #5e5e5e;
    color: #ffffff;

    .text {
        width: 100%;
        max-width: 100%;
        font-size: 12px;
        text-align: center;
    }
    
}
