.b-benefits {
    background-image: url(../img/benefits.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    background-attachment: fixed;
    padding: 40px 0;
    text-align: center;

    .item {
        width: 240px;
        text-align: center;
        font-size: 24px;
        font-weight: 900;

        .small {
            font-weight: 700;
            font-size: 18px;
        }

        @include media-breakpoint-down(md) {
            margin: 0 auto;
            margin-bottom: 20px;
            font-size: 18px;
            .small{
                font-size: 16px;
            }
        }

    }

    .img {
        width: 145px;
        height: 145px;
        border: 4px solid #fff;
        border-radius: 50%;
        background-color: rgba(#fff, .33);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 10px;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

}
