.b-header {
    padding: 20px 0 80px;
    min-height: 80vh;
    background-image: url(../img/main-bg.jpg);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
        height: auto;
        min-height: 0;
        padding: 20px 0 40px;
    }

    .container-fluid {
        position: relative;
        z-index: 3;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../img/overlay.png);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto;
        content: '';
        @include media-breakpoint-down(lg) {
            background-image: linear-gradient(135deg, rgb(254, 161, 3) 0%, rgb(251, 10, 10) 100%);
            opacity: .5;
        }
    }

    &__after {
        position: absolute;
        z-index: 2;
        width: 50%;
        bottom: 10px;
        right: -90px;
        height: auto;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .nav-toggler {
        position: absolute;
        left: 0;
    }

    &__nav {
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include media-breakpoint-down(md) {

            position: absolute;
            top: 56px;
            left: 0;
            width: 100%;
            text-align: center;
            background-color: rgb(254, 161, 3);
            color: #ffffff;
            height: auto;
            padding: 20px 10px;
            display: none;

            .item {
                display: block;
                margin-right: 0;
                margin-bottom: 20px;
                color: #ffffff;
            }

        }

        .item {

            margin-right: 55px;
            text-transform: uppercase;
            font-size: 18px;
            color: #434343;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            &:last-child {
                margin-right: 0;
            }

            @include media-breakpoint-down(md) {
                margin-right: 20px;
            }

        }
    }

    &__contacts {
        width: 340px;
        text-align: center;
        padding-top: 10px;
        color: #ffffff;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        .messenger {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
                margin: 0 15px;
            }

        }

        .tell {
            display: block;
            text-align: center;
            border: 3px solid #fff;
            padding: 4px 0;
            font-weight: 900;
            font-size: 30px;
            color: #ffffff;
            text-decoration: none;
            margin-bottom: 6px;
            border-radius: 20px;
            @include media-breakpoint-down(md) {
                font-size: 20px;
            }
            &:hover {
                color: #fb0a0a;
                background-color: #ffffff;
            }
        }

        .callback {
            font-size: 18px;
            color: #ffffff;
            text-decoration: none;
            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
            &:hover {
                text-decoration: underline;
            }
        }

    }

    &__first-screen {
        margin-bottom: 25px;
    }

    &__central {
        padding: 60px 30px;
        width: 820px;
        margin: 0 auto;
        border-radius: 20px;
        background-color: rgba(#fff, .57);
        text-align: center;

        @include media-breakpoint-down(md) {
            width: 100%;
            padding: 20px;
        }

        h1, .h1 {
            margin-bottom: 15px;
            text-transform: uppercase;
            font-weight: 900;
            color: #000000;
            line-height: 1;
            font-size: 36px;
            span {
                color: #fb0a0a;
            }
            @include media-breakpoint-down(md) {
                font-size: 22px;
                line-height: 1.2;
            }
        }

        .item {

            padding: 0 10px;
            font-size: 24px;
            border-right: 1px solid #fff;
            margin-bottom: 40px;

            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
                padding-bottom: 10px;
                display: block;
                border-right: none;
                border-bottom: 1px solid #fff;
                font-size: 18px;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: none;
                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                }
            }

        }

    }

}