.b-how {

    background-color: #ffffff;
    box-shadow: 0 -4px 16px rgba(#000, .6);
    background-image: url(../img/how-bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding-bottom: 20px;
    position: relative;
    background-size: auto 100%;

    @include media-breakpoint-down(md) {
        background-image: none;
    }

    &:after {
        width: 659px;
        height: 431px;
        background-image: url(../img/car2.png);
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .styled-title {

        padding: 30px 60px;
        display: inline-block;
        background-color: rgba(#fff, .68);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            padding: 20px 0;
            background-color: transparent;
        }

        &:before {
            bottom: 15px;
            @include media-breakpoint-down(md) {
                bottom: 0;
            }
        }
    }

    p {
        margin-bottom: 25px;
    }

    .step {
        display: block;
        width: 80%;
        margin: 0 auto 15px;
        padding: 5px 7px;
        border-radius: 100px;
        background-color: #ffffff;
        box-shadow: 0 0 16px rgba(#000, .2);
        font-size: 18px;
        font-weight: bold;
        position: relative;
        @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 20px;
            border-radius:20px;
            text-align: center;
            padding-bottom: 20px;
        }
        span {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .round {
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background-color: #fc4c07;
        text-align: center;
        line-height: 43px;
        font-size: 24px;
        font-weight: 900;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        @include media-breakpoint-down(md) {
            margin: 0 auto 10px;
            display: block !important;
        }
    }

}
