.b-regular {
    padding: 40px 0 46px;
    position: relative;
    &:before {
        background-image: linear-gradient(90deg, rgb(254, 161, 3) 0%, rgb(251, 10, 10) 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 6px;
        content: '';
    }

}
